import React, { Suspense, useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation} from 'react-router-dom';
import CommonHeader from '../views/layout/header';
import CommonFooter from '../views/layout/footer'
import routesArr from './utils';
import './index.less'
import { useViewport } from '../hooks/useViewPort';

const HomePage = React.lazy(() => import('../views/HomePage'))
// const NewList = React.lazy(() => import('../views/newList'))
// const NewDetail = React.lazy(() => import('../views/newDetail'))
// const Community = React.lazy(() => import('../views/community'))
// const Collections = React.lazy(() => import('../views/Collections'))
// const Agreement = React.lazy(() => import("../views/Agreement"))
const AppRouter:React.FC = () => {
  const [hidden, setHidder] = useState(false)
  const rect = useViewport()
  window.onscroll = function () {
    var top_num = document.documentElement.scrollTop || document.body.scrollTop;    
    top_num >= 1000 ? setHidder(true) : setHidder(false)
  }
  const moveToTop = () => {
    window.scrollTo(0, 0)
  }
  useEffect(()=>{
    moveToTop()
  },[window.location.pathname])

  return (
    <Router>
      <CommonHeader></CommonHeader>
      <Suspense
        fallback={<div  className='w100 text-white flex justify_center align_center fs_5 fw_b' style={{ height: '100vh',background:'#27282f' }}>Loadings...</div>}
        >
          <Routes>
                {
                    routesArr.map((item, index) => {
                        return (
                            <Route key={index} path={item.pathname} element={<item.component/>} />
                        )
                    })
                }
            <Route path='*' element={<HomePage />} />
          </Routes>
        {rect.windowWidth > 1024 && 
        <span className={`cursor scroll-top ${hidden ? '' : 'hidder'} flex justify_center align_center`} onClick={() => { moveToTop() }}>
            <i className='iconfont icon-icon-top'></i>
        </span>}

      </Suspense>
      <CommonFooter></CommonFooter>
    </Router>
  )
}

export default AppRouter;
